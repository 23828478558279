<script>
// src/components/tv/widgets/WeatherWidget.vue
export default {
  props: {
    loaded_ubicaciones: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected_location: {},
      selected_locations: [],
    };
  },

  computed: {
    isLocationCloned() {
      return this.selected_locations.some(
        (locObj) => locObj.ubicacion === this.selected_location.ubicacion
      );
    },
  },

  created() {
    if (this.loaded_ubicaciones) {
      // eslint-disable-next-line no-undef
      this.selected_locations = JSON.parse(JSON.stringify(this.loaded_ubicaciones));
    }
  },

  mounted() {
    const autocompleteInput = this.$refs.autocompleteInput;
    // eslint-disable-next-line no-undef
    const autocomplete = new google.maps.places.Autocomplete(autocompleteInput);

    // eslint-disable-next-line no-undef
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      const place = autocomplete.getPlace();
      const ubicacion = place.name;
      const [latitud, longitud] = [
        String(place.geometry.location.lat()),
        String(place.geometry.location.lng()),
      ];
      this.selected_location = { ubicacion, latitud, longitud };
    });
  },

  methods: {
    addLocation() {
      if (this.selected_locations.length >= 6) {
        return;
      }
      this.selected_locations.unshift(this.selected_location);
      this.$emit("setSelectedLocations", this.selected_locations);
      this.$refs.autocompleteInput.value = "";
    },
    deleteLocation(index) {
      this.selected_locations.splice(index, 1);
      this.$emit("setSelectedLocations", this.selected_locations);
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="alert text-center"
          :class="
            selected_locations.length >= 6 ? 'alert-warning' : 'alert-info'
          "
        >
          Puedes agregar un máximo de <strong>6 Lugares</strong>
        </div>
      </div>
    </div>
    <div
      class="card border-0 card-shadow"
      :class="selected_locations.length >= 6 ? 'disabled' : ''"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-lg-10">
            <input
              ref="autocompleteInput"
              id="autocomplete"
              type="text"
              class="form-control input-custom"
            />
          </div>
          <div class="col-12 col-lg-2">
            <button
              @click="addLocation()"
              type="button"
              :disabled="isLocationCloned"
              class="btn btn-custom btn-custom-color-white rounded"
            >
              <font-awesome-icon
                icon="plus"
                :class="isLocationCloned ? '' : 'color-secondary'"
              />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <h5 class="text-secondary">En uso</h5>
        <div
          v-if="selected_locations.length === 0"
          class="alert alert-light border text-center mb-0"
        >
          <p class="text-secondary text-opacity-75 mb-0">
            Aún no se ha agregado ningún lugar
          </p>
        </div>
        <ul v-if="selected_locations.length !== 0" class="list-group mt-3">
          <li
            v-for="(location, index) in selected_locations"
            :key="index"
            class="list-group-item d-flex justify-content-between align-items-center px-2"
          >
            <p class="d-flex align-items-center text-secondary mb-0">
              <span class="badge border color-secondary me-2">
                {{ index + 1 }}
              </span>
              {{ location.ubicacion }}
            </p>
            <span>
              <span
                v-if="selected_locations.length > 1"
                class="badge border font-400 text-secondary me-2"
              >
                <i class="fa-regular fa-clock"></i>
                20 seg
              </span>
              <button
                @click="deleteLocation(index)"
                type="button"
                class="btn btn-custom-color-white rounded"
              >
                <i class="fa-regular fa-trash-can text-secondary"></i>
              </button>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
